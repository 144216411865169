import CognitoUtil from '../aws/cognito/cognitoUtil';

import { tsvParse } from  "d3-dsv";
import { timeParse, timeFormat } from "d3-time-format";
import moment from 'moment';
import displayVariableTypes from "./displayVariableTypes";

const parseDate = timeParse("%Y-%m-%d");
const formatEpoch = timeFormat("%Y%m%d");

function fileSizeSI(a,b,c,d,e){
  return (b=Math,c=b.log,d=1e3,e=c(a)/c(d)|0,a/b.pow(d,e)).toFixed(2)
  +' '+(e?'kMGTPEZY'[--e]+'B':'Bytes')
}

function parseDataBins() {
    return function(d) {
        d.date = parseDate(d.date);
        d.x = +formatEpoch(d.date);
        d.tx_other         = d.tx_other > 0 ? +d.tx_other : 0;
        d.rx_other         = d.rx_other > 0 ? +d.rx_other : 0;
        d.tx_total         = d.tx_total > 0 ? +d.tx_total : 0;
        d.rx_total         = d.rx_total > 0 ? +d.rx_total : 0;
        d.tx_hotspot         = d.tx_hotspot > 0 ? +d.tx_hotspot : 0;
        d.rx_hotspot         = d.rx_hotspot > 0 ? +d.rx_hotspot : 0;
        d.tx_streaming         = d.tx_streaming > 0 ? +d.tx_streaming : 0;
        d.rx_streaming         = d.rx_streaming > 0 ? +d.rx_streaming : 0;
        d.tx_unknown         = d.tx_unknown > 0 ? +d.tx_unknown : 0;
        d.rx_unknown         = d.rx_unknown > 0 ? +d.rx_unknown : 0;
        d.tx_system         = d.tx_system > 0 ? +d.tx_system : 0;
        d.rx_system         = d.rx_system > 0 ? +d.rx_system : 0;

        d.driving_seconds  = +d.driving_seconds;
        d.parked_seconds   = +d.parked_seconds;
        d.offline_seconds  = +d.offline_seconds;
        d.driving_fraction  = +d.driving_fraction;
        d.parked_fraction   = +d.parked_fraction;
        d.offline_fraction  = +d.offline_fraction;

        d.total_bytes = d.tx_other + d.rx_other + 
            d.tx_unknown + d.rx_unknown +
            d.tx_streaming + d.rx_streaming +
            d.tx_hotspot + d.rx_hotspot +
            d.tx_unknown + d.rx_unknown +
            d.tx_system + d.rx_system;

        return d;
    };
}


function parseSensorData() {
    return function(d) {
        d.date = parseDate(d.date);
        d.min = +d.min;
        d.max   = +d.max;
        d.mean   = +d.mean;
        d.stdev   = +d.stdev;

        return d;
    };
}

/**
 * Constructs a fulfillmentApi object for a particular stage.
 * The api object has get, post methods to call fetch with the right
 * arguments and return the promise from the fetch() call.
 *
 * Usage:
 *
 *    fulfillmentApi('test').post('/accounts', { email: 'user@example.com' }).then(...);
 *    fulfillmentApi('beta3').get('/orders').then(...);
 *
 * */
const fulfillmentApi = (stage) => {
    const BASE_URL_TEMPLATE = process.env.REACT_APP_FF_API_BASE_URL;
    const dotstage = "prod" == stage ? "" : `.${stage}`
    const baseurl = BASE_URL_TEMPLATE.replace('{.stage}', dotstage)
    const apiCall = (relurl, method, bodyobj=null) => {
        const fetchOptions = {
            method,
            headers: {
                Authorization: CognitoUtil.getCurrentUserToken(),
            },
        };
        if (bodyobj) {
            fetchOptions.headers['Content-Type'] = 'application/json';
            fetchOptions.body = JSON.stringify(bodyobj) 
        }
        return fetch(`${baseurl}${relurl}`, fetchOptions);
    }
    return {
        get: (relurl) => apiCall(relurl, 'GET'),
        delete: (relurl) => apiCall(relurl, 'DELETE'),
        patch: (relurl, bodyobj={}) => apiCall(relurl, 'PATCH', bodyobj),
        post: (relurl, bodyobj={}) => apiCall(relurl, 'POST', bodyobj),
        put: (relurl, bodyobj={}) => apiCall(relurl, 'PUT', bodyobj),
    }
}

export default class RavenDataStore {

    constructor(dataChangeCallback) {

        /* State.  Ravens is the list of Raven Objects, geojson is the data for the map.
            Raven objects are in the form of:
                {
                    item: {}, // side panel items in hierarchy
                    otabuilds: {}, // ota build data
                    sensors: {}, // sensor data
                    billing: {}, // sensor data
                }
        */
        this.data = {
            ravens: [],
            users: [],
            accounts: [],
            plans: [],
            channels: [],
            geojson: null,
            stage: null,
            orders: [],
            allusers: []
        };

        // Grab the middleman address from the build system
        this.prefix1 = process.env.REACT_APP_KLOUD_API_BASE_URL + 'query/';
        this.prefix2 = process.env.REACT_APP_KLOUD_API_BASE_URL + 'level2/';

        this.dataChangeCallback = dataChangeCallback;
        
        this.fetchFlags = {};
    }

    setAndCheckFetchFlag = (raven, type) => {
        if(!this.fetchFlags[raven])
        {
            this.fetchFlags[raven] = {};
        }

        if(!this.fetchFlags[raven][type])
        {
            this.fetchFlags[raven][type] = 'Y';
            return true;
        }

        return false;
    }

    clearFetchFlag = (raven, type) => {
        this.fetchFlags[raven][type] = undefined;
    }

    queryLoop = (url, parseFunction, waitFunction) => {

        var me = this;

        if(!parseFunction)
        {
            // No parse function!?!  Why bother fetching?
        }

        return fetch(url, { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() }
             }) .then((response) => {

            return response.json();
          }).then((json) => {

                var resultsUrl = json.results;

                if(!resultsUrl || !resultsUrl.includes("http"))
                {
                    console.log("FAILED TO GET RESULTS URL");
                    return;
                }

                var attempts = 0;

                function fetchResult() {

                    fetch(resultsUrl, { cache: "no-store" } )
                      .then((response) => {
                        return response.json();
                    }).then((json) => {

                        if(json.status === 'partial')
                        {
                            parseFunction(json.result);
                            resultsUrl = json.next;
                            attempts=0;
                            setTimeout(fetchResult, 5000);
                        }

                        else if(json.status !== 'complete')
                        {

                            var newData;

                            // Not success, reinit, but accept this fetch
                            attempts++;
                            if(attempts <= 20)
                            {
                                newData = "Loading (" + attempts + "/20)";
                                setTimeout(fetchResult, 5000);
                            }
                            else
                            {
                                newData = "Failed to load.";
                            }

                            try {
                                if(waitFunction)
                                    return waitFunction(newData);
                            } catch(err)
                            {
                                console.log("Error in queryLoop wait function:", err);
                            }
                        }

                        else
                        {
                            try {
                                parseFunction(json.result);
                            } catch(err)
                            {
                                console.log("Error in queryLoop parsing function:", err);
                            }
                        }

                    }).catch((err) => {
                        // any error, then try again
                        console.log("error fetching", err);
                        attempts++;
                        if(attempts <= 20)
                        {
                            // Another 2 seconds for second attempt, 5 seconds thereafter
                            setTimeout(fetchResult, attempts === 1 ? 2000 : 5000);
                        }
                    });

                };
                
                // First attempt is 2 seconds
                setTimeout( fetchResult, 2000);
    
        });

    }


    /* FIXME - use this, and store the results in the object */
    getRavenDisplayTypes = (input) => {

      return fetch("" + this.prefix1 + "ajax" +
            "?stage=" + this.data.stage +
            "&type=ravenDisplayTypes",
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } }
        )
        .then((response) => {
          return response.json();
        }).then((json) => {

          return { options: json };

        });
    }

    /* FIXME - use this, and store the results in the object */
    getRavenStages = (input) => {
      return fetch("" + this.prefix1 + "ajax" +
            "?type=ravenStages",
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } }
        )
        .then((response) => {
          return response.json();
        }).then((json) => {
          return { stages: json };
        });
    }

setStage = (stage) => {
    // clear out all cached data
    this.setState({
        stage: stage,
        accounts: [],
        ravens: [],
        users: [],
        geojson: null,
    });
}

getStage = () => {
    return this.data.stage;
}

/* FIXME - use this, and store the results in the object */
queryChannels(stage)
{
    // Refresh the login before attempting to grab account data
       
    CognitoUtil.refreshLogin();

    var me = this;

    if(!this.setAndCheckFetchFlag("channels", "all"))
    {
        // Send the data change callback on the next event slice.  Avoids React error about recursion
        setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
        return;
    }

    var url = "" + this.prefix1 + "ajax" +
        "?stage=" + stage +
        "&type=channels";

    return fetch(url,
        { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
      .then((response) => {
        return response.json();
      }).then((json) => {
          // We grabbed the json.  Put it in the state

          this.clearFetchFlag("channels", "all");

          if(Array.isArray(json))
          {
              me.data.channels = json;
          }

          // this.setState({stage: stage, geojson: json[0], ravens: list });
          me.dataChangeCallback(me.data);

      }, (reason) => {
          // Ignore Failure

          // this.setState({stage: stage, geojson: geojsondata});
          me.dataChangeCallback(me.data);
      }
      );
}

/* FIXME - use this, and store the results in the object */
getRavenDisplayVariables = (input) => {
  return fetch("" + this.prefix1 + "ajax" +
        "?stage=" + this.data.stage +
        "&type=ravenDisplayVariables",
        { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } }
    )
    .then((response) => {
      return response.json();
    }).then((json) => {
      return { options: json };
    });
}

queryRaven(ravenid)
{
    var me = this;

    if(!ravenid)
    {
        return false;
    }

    if(!this.setAndCheckFetchFlag(ravenid, 'raven')) 
    {
        // Send the data change callback on the next event slice.  Avoids React error about recursion
        setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
        return;
    }

    // CognitoUtil.refreshLogin();

    // perform ajax calls to get it the next state
    var url = "" + this.prefix1 + "ajaxbig"
            + "?stage=" + this.data.stage
            + "&type=raven"
            + "&raven=" + ravenid;

    return this.queryLoop(url, (json) => {
          this.clearFetchFlag(ravenid, 'raven');

          var parsed;

          var ravenIndex = null;

          if(!json.raven)
              return false;

          if( json.raven.objects.length > 1)
              return false;

          if( !json.raven.data)
              return false;

          if( !json.raven.data.features)
              return false;

          if( json.raven.data.features.length > 1)
              return false;

          var obj = json.raven.objects[0];
          var baseobj = json.raven.baseobjects[0];

          var returnedravenid = obj ? obj['Id'] : -1;

          this.addToGeoJSON(json.raven);

          ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === returnedravenid }
            );
          if(ravenIndex < 0)
          {
              ravenIndex = me.data.ravens.length;
                me.data.ravens[ravenIndex] = {};
          }

          var newFeature = me.data.ravens[ravenIndex];

          if(!newFeature)
          {
              newFeature = {};
          }

          newFeature.lastupdate = Date.now();
          newFeature.item = obj;
          if(json.raven.data.features[0])
          {
              newFeature.geometry = json.raven.data.features[0].geometry;
          }
          newFeature.minitem = baseobj;

              if(json.databins)
              {
                  // Returns: raven, ota, datausage, timerange 

                  parsed = tsvParse(json.databins, parseDataBins());

                  newFeature.databins = parsed;

              }

              if(json.timerange)
              {

                  newFeature.mintime = json.timerange.min;
                  newFeature.maxtime = json.timerange.min;

              }

              if(json.sensors)
              {
                  var mindate, maxdate;
                  var i = 0;

                  newFeature.sensors = {};

                  for(i = 0; i < 100; i ++)
                  {
                      var name = "sensor" + i;
                      if(json.sensors[name])
                      {
                          parsed = tsvParse(json.sensors[name], parseSensorData());
                          if(!mindate || mindate > parsed[0].date)
                              mindate = parsed[0].date;
                          if(!maxdate || maxdate < parsed[parsed.length-1].date)
                              maxdate = parsed[parsed.length-1].date;

                          newFeature.sensors[name] = parsed;

                      }

                  }

                  newFeature.mintime = json.timerange.min;
                  newFeature.maxtime = json.timerange.min;

              }

              if(json.billing)
              {
                  newFeature.billing = json.billing;
              }

              if(json.sensordaterange)
              {
                  newFeature.sensordaterange = json.sensordaterange;
              }

              if('SIM' in newFeature['item']) {
                var sim = newFeature['item']['SIM'];
                  me.querySIMDataUsage(newFeature);
                  me.querySIMStatus(newFeature);
              }

              me.queryOta(newFeature);

              me.queryDataBins(newFeature);

              me.dataChangeCallback(me.data);
        }, (errcode) => {
            // FIXME- report load error for raven query
        });

    }

    querySIMDataUsage = (raven) =>
    {
        var me = this;
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var stage = curraven['Build']['Stage'];

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "getsimdatausage"
                + "?stage=" + stage
                + "&raven=" + ravenid;

        var res = fetch(url, { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() }})
            .then(response => response.json())
            .then(data => {
                console.log(data)
                // only access data & update page if expected key is present
                if ("ctdDataUsage" in data) {
                  var dataUsageBytes = data['ctdDataUsage'];
                  var dataUsageGb = fileSizeSI(dataUsageBytes);
                  curraven['SIM']['Data Usage this Cycle'] = dataUsageGb;
                  me.dataChangeCallback(this.data);
                }
        });
    };

    querySIMStatus = (raven) =>
    {
        var me = this;
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var stage = curraven['Build']['Stage'];

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "getsimsession"
                + "?stage=" + stage
                + "&raven=" + ravenid;

        var res = fetch(url, { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() }})
            .then(response => response.json())
            .then(data => {
                // only access data & update page if expected keys are present
                if (("dateSessionStarted" in data) && ("dateSessionEnded" in data)) {
                  var started = data['dateSessionStarted'];
                  var ended = data['dateSessionEnded'];
                  var status = "False";
                  // if both are null there has never been a session
                  if (started == null && ended == null) {
                      status = "False";
                  }
                  // if only ended is null, it is in a session
                  else if (ended == null) {
                      status = "True";
                  }
                  curraven['SIM']['In Session'] = status;
                  me.dataChangeCallback(this.data);
                }
        });
    };

    queryBilling = (raven) =>
    {
        var me = this;

        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var stage = curraven['Build']['Stage'];

        if(!this.setAndCheckFetchFlag(ravenid, 'billing')) 
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        // CognitoUtil.refreshLogin();

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajaxbig"
                + "?stage=" + this.data.stage
                + "&type=billing"
                + "&raven=" + ravenid;

        return this.queryLoop(url, (json) => {
              this.clearFetchFlag(ravenid, 'billing');

              // Requery as it is async and may have changed
              var ravenIndex = me.data.ravens.findIndex((elem) => 
                  { return elem.item['Id'] === ravenid }
              );

              if(!me.data.ravens[ravenIndex])
                  me.data.ravens[ravenIndex] = {}

              let raven = me.data.ravens[ravenIndex];
              raven.billing = json;

              me.dataChangeCallback(this.data);
            }, (errcode) => {
                // FIXME- report load error for billing query

              // Requery as it is async and may have changed
              var ravenIndex = me.data.ravens.findIndex((elem) => 
                  { return elem.item['Id'] === ravenid }
              );

              if(!me.data.ravens[ravenIndex])
                  me.data.ravens[ravenIndex] = {}

              let raven = me.data.ravens[ravenIndex];
              raven.billing = "Error loading billing info";
              me.dataChangeCallback(this.data);
            });
    }


    queryOta = (raven) =>
    {

        var me = this;

        var curraven = raven.item;

        var ravenid = curraven['Id'];
        var ravenunitid = curraven['Raven']['Unit Id'];
        var stage = curraven['Build']['Stage'];

        if(!this.setAndCheckFetchFlag(ravenid, 'ota'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "listota"
                + "?stage=" + stage
                + "&raven=" + ravenid
                + "&ravenunit=" + ravenunitid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {
            return response.json();
        }).then((json) => {

            this.clearFetchFlag(ravenid, 'ota');

            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(json.length === 0)
            {

                // Grab the Raven fresh, as we don't know how long we have been away

                let raven = me.data.ravens[ravenIndex];
                raven.otabuilds = {'OTA Builds': "No New Builds"};

            }
            else
            {
                // We grabbed the json.  Put it in the state
                if(!me.data.ravens[ravenIndex])
                    me.data.ravens[ravenIndex] = {}

                let raven = me.data.ravens[ravenIndex];
                raven.otabuilds = json;

            }

            me.dataChangeCallback(this.data);

        });

    }

    handleLoadMoreDataBins = (start, end) => {

        if(start === end)
            return;

        this.queryDataBins(null, start, end);
        
    }

    queryDataBins(raven, starttime, endtime)
    {
        return this.queryDataUsageInternal("databins", parseDataBins, raven, starttime, endtime);
    }

    queryDataUsageInternal(datatype, parseFunction, raven, starttime, endtime)
    {

        // console.log("Query Data Usage Internal", raven, starttime, endtime);

        if(!raven)
        {
            return false;
        }

        var ravenid = raven['Id'];

        if(!ravenid)
        {
            return false;
        }

        var me = this;

        var ravenIndex = me.data.ravens.findIndex((elem) => 
            { return elem.item['Id'] === ravenid }
        );

        var curFeature = me.data.ravens[ravenIndex];
        if(!curFeature['pendingQueries'])
        {
            curFeature['pendingQueries'] = {};
        }

        if(!curFeature['pendingQueries'][datatype]) {
            curFeature['pendingQueries'][datatype] = { };
        }

        // If it is pending, queue up behind this one
        if(curFeature['pendingQueries'][datatype].pending)
        {
            var nextQuery = curFeature['pendingQueries'][datatype];
            if(nextQuery && nextQuery.start && nextQuery.end)
            {
                if(nextQuery.start > starttime) nextQuery.start = starttime;
                if(nextQuery.end < endtime) nextQuery.end = endtime;
            }
            else
            {
                nextQuery = {
                    pending: true,
                    start: starttime,
                    end: endtime,
                };
            }

            curFeature['pendingQueries'][datatype] = nextQuery;

            return;
        }

        // We are going to start querying, mark as such
        curFeature['pendingQueries'][datatype].pending = true;

        // CognitoUtil.refreshLogin();

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "tsv"
                + "?stage=" + this.data.stage
                + "&type=" + datatype
                + "&raven=" + ravenid
                + "&start=" + moment(starttime).unix()
                + "&end=" + moment(endtime).unix();

        return this.queryLoop(url, (result) => {
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            var curFeature = me.data.ravens[ravenIndex];

            curFeature['pendingQueries'][datatype].pending = false;

            if(curFeature[datatype] && (typeof curFeature[datatype] === 'string' || curFeature[datatype] instanceof String))
            {
                // Clear out the loading string
                curFeature[datatype] = []
            }
            else if(!curFeature[datatype])
            {
                curFeature[datatype] = [];
            }

            var newData = curFeature[datatype];

            var tsv = result;

            // Finally got some data back
            var parsed = tsvParse(tsv, parseFunction());

            parsed.forEach(function (newitem, index) {

                var existingindex = curFeature[datatype].findIndex(function(item) { return item.date >= newitem.date });

                if(existingindex >= 0)
                {
                    var existingItem = curFeature[datatype][existingindex];

                    if(moment(existingItem.date).unix() === moment(newitem.date).unix())
                    {  
                         if(newitem.total_bytes > existingItem.total_bytes)
                            curFeature[datatype].splice(existingindex, 1, newitem);
                    }
                    else
                    {
                        curFeature[datatype].push(newitem);
                    }
                }
                else
                {
                    curFeature[datatype].push(newitem);
                }
                
            });

              newData.sort( function (a,b) { return new Date(a.date) - new Date(b.date); } );

              curFeature[datatype] = newData;
              me.dataChangeCallback(me.data);

            // If there is a queued query, run that one now
            var nextQuery = curFeature['pendingQueries'][datatype];
            if(nextQuery && nextQuery.start && nextQuery.end)
            {
                curFeature['pendingQueries'][datatype] = {};
                this.queryDataUsageInternal(datatype, parseFunction, raven, nextQuery.start, nextQuery.end);
            }

        }, (errcode) => {
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            var curFeature = me.data.ravens[ravenIndex];

            if(!curFeature[datatype] || (typeof curFeature[datatype] === 'string' || curFeature[datatype] instanceof String))
            {
                // Put in the loading string if there isn't already data there
                curFeature[datatype] = errcode;
            }

            me.dataChangeCallback(me.data);
        });


    }


    getCurFeatureList(geojson, displayVar)
    {
        if(!geojson)
        {
            return [];
        }

        var func = (obj, prop) => { return obj.properties[prop]; };

        for(var i = 0 ; i < displayVariableTypes.length; i++)
        {
            if(displayVariableTypes[i].value !== displayVar)
            {
                continue;
            }

            if('type' in displayVariableTypes[i])
            {
                if(displayVariableTypes[i]['type'] === 'date')
                {
                    func = (obj, prop) => { return moment.unix(obj.properties[prop]).fromNow(); };
                }
                else if(displayVariableTypes[i]['type'] === 'time')
                {
                    func = (obj, prop) => { return moment.duration(obj.properties[prop], 'seconds').humanize(); };
                }
            }
        }

        var ravens = geojson.data.features.map((obj) => {
            return {
                value: func(obj,displayVar),
                item: geojson.objects[obj.properties.index],
                geometry: obj.geometry
            };
        });

        return ravens;
    }

    // Get the list of Raven units 
    // Currently this calls into the master list, but it can just
    // get the Raven unit IDs and enclosure serial numbers
    getRavenUnits(stage)
    {
        return this.refreshRavenList(stage, 'all', 'ts', '');
    }

    getRavenEvents(raven, date)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];

        if(!this.setAndCheckFetchFlag(ravenid, 'events'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajaxbig"
                + "?stage=" + this.data.stage
                + "&type=events"
                + "&raven=" + ravenid;

        if(date)
            url += '&end=' + date;

        return this.queryLoop(url, (json) => {
            this.clearFetchFlag(ravenid, 'events');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {

                if(!me.data.ravens[ravenIndex].events)
                {
                    me.data.ravens[ravenIndex].events = json;

                    if(json && json.length > 0)
                    {
                        var ets1 = moment(json[0].ts).unix();
                        var ets2 = moment(json[json.length-1].ts).unix();

                        me.data.ravens[ravenIndex].oldest_event = ets1 < ets2 ? ets1 : ets2;
                    
                    }
                }
                else
                {
                    // reconcile

                    var eventstoadd = []
                    var existingindex;

                    var oldest_event = moment().unix();

                    var newlistlength = json.length;
                    for(var i = 0; i < newlistlength; i++)
                    {
                        existingindex = me.data.ravens[ravenIndex].events.find((elem) => 
                            { return elem.ts === json[i].ts && elem.type === json[i].type });
                        if(!existingindex)
                        {
                            eventstoadd.push(json[i]);
                        }

                        var ets = moment(json[i].ts).unix();

                        if(ets < oldest_event)
                        {
                            oldest_event = ets;
                        }
                    }

                    if(eventstoadd.length > 0)
                        me.data.ravens[ravenIndex].events.push(...eventstoadd);

                    if(!me.data.ravens[ravenIndex].oldest_event)
                    {
                        me.data.ravens[ravenIndex].oldest_event = oldest_event;
                    }
                    else if(oldest_event < me.data.ravens[ravenIndex].oldest_event)
                    {
                        me.data.ravens[ravenIndex].oldest_event = oldest_event;
                    }
                }

            }

            me.dataChangeCallback(me.data);
        }, (errcode) => {
        });

    }

    getSupportedPids(raven)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];

        if(!this.setAndCheckFetchFlag(ravenid, 'pids'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajaxbig"
                + "?stage=" + this.data.stage
                + "&type=obdpids"
                + "&raven=" + ravenid;

        return this.queryLoop(url, (json) => {
                this.clearFetchFlag(ravenid, 'pids');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                this.clearFetchFlag(ravenid, 'pids');

                if(typeof json !== "object")
                {
                    me.data.ravens[ravenIndex].pids = [];
                }
                else
                    me.data.ravens[ravenIndex].pids = json;
            }

            me.dataChangeCallback(me.data);
        }, (errcode) => {

        });

    }

    getRavenDTCs(raven)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];

        var me = this;

        if(!this.setAndCheckFetchFlag(ravenid, 'dtc'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajaxbig"
                + "?stage=" + this.data.stage
                + "&type=dtc"
                + "&raven=" + ravenid;

        return this.queryLoop(url, (json) => {
            this.clearFetchFlag(ravenid, 'dtc');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                if(typeof json !== "object")
                {
                    me.data.ravens[ravenIndex].dtc = [];
                }
                else
                {
                    me.data.ravens[ravenIndex].dtc = json;
                }
            }

            me.dataChangeCallback(me.data);

        }, (error) => {

            // Assume error means no DTC
            this.clearFetchFlag(ravenid, 'dtc');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                me.data.ravens[ravenIndex].dtc = [];
            }

            me.dataChangeCallback(me.data);
        });

    }

    getNotes(raven)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var ravenunitid = curraven['Raven']['Unit Id'];

        var me = this;

        if(!this.setAndCheckFetchFlag(ravenid, 'notes'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajaxbig"
                + "?stage=" + this.data.stage
                + "&type=notes"
                + "&raven=" + ravenid
                + "&ravenunit=" + ravenunitid;

        return this.queryLoop(url, (json) => {
            this.clearFetchFlag(ravenid, 'notes');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                if(typeof json !== "object")
                {
                    me.data.ravens[ravenIndex].notes = [];
                }
                else
                {
                    me.data.ravens[ravenIndex].notes = json;
                }
            }

            me.dataChangeCallback(me.data);

        }, (error) => {

            // Assume error means no DTC
            this.clearFetchFlag(ravenid, 'notes');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                me.data.ravens[ravenIndex].notes = [];
            }

            me.dataChangeCallback(me.data);
        });

    }

    getRavenTombstones(raven, date)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var uuid = curraven['Raven']['Raven UUID'];
        var version = curraven['Build']['OS Version'];

        if(!this.setAndCheckFetchFlag(ravenid, 'tombstones'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "logs"
                + "?stage=" + this.data.stage
                + "&type=tombstones"
                + "&raven=" + ravenid
                + "&uuid=" + uuid
                + "&version=" + version;

        if(date)
            url += '&end=' + date;


        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return [];
            }
        }).then((json) => {

            this.clearFetchFlag(ravenid, 'tombstones');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                if(!me.data.ravens[ravenIndex].tombstones)
                {
                    if(typeof json !== "object")
                    {
                        me.data.ravens[ravenIndex].tombstones = [];
                    }
                    else if(!Array.isArray(json))
                    {
                        me.data.ravens[ravenIndex].tombstones = [];
                    }
                    else
                    {
                        me.data.ravens[ravenIndex].tombstones = json;

                        if(json && json.length > 0)
                        {
                            var ets1 = moment(json[0].ts).unix();
                            var ets2 = moment(json[json.length-1].ts).unix();

                            me.data.ravens[ravenIndex].oldest_tombstone = ets1 < ets2 ? ets1 : ets2;
                        
                        }
                    }
                }
                else
                {
                    // reconcile

                    var toadd = []
                    var existingindex;

                    var oldest_tombstone = moment().unix();

                    var newlistlength = json.length;
                    for(var i = 0; i < newlistlength; i++)
                    {
                        existingindex = me.data.ravens[ravenIndex].tombstones.find((elem) => 
                            { return elem.ts === json[i].ts && elem.type === json[i].type });
                        if(!existingindex)
                        {
                            toadd.push(json[i]);
                        }

                        var ets = moment(json[i].ts).unix();

                        if(ets < oldest_tombstone)
                        {
                            oldest_tombstone = ets;
                        }
                    }

                    if(toadd.length > 0)
                        me.data.ravens[ravenIndex].tombstones.push(...toadd);

                    if(!me.data.ravens[ravenIndex].oldest_tombstone)
                    {
                        me.data.ravens[ravenIndex].oldest_tombstone = oldest_tombstone;
                    }
                    else if(oldest_tombstone < me.data.ravens[ravenIndex].oldest_tombstone)
                    {
                        me.data.ravens[ravenIndex].oldest_tombstone = oldest_tombstone;
                    }
                }
            }

            me.dataChangeCallback(me.data);

        }).catch( (error) => {

            // Assume error means no data
            this.clearFetchFlag(ravenid, 'tombstones');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                me.data.ravens[ravenIndex].tombstones = [];
            }

            me.dataChangeCallback(me.data);
        });

    }

    getRavenHistoryStatus(raven)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var ruid = curraven['Raven']['Unit Id'];

        if(!this.setAndCheckFetchFlag(ravenid, 'ravenstatus'))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajax"
                + "?stage=" + this.data.stage
                + "&type=ravenstatus"
                + "&raven=" + ruid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return [];
            }
        }).then((json) => {

            this.clearFetchFlag(ravenid, 'ravenstatus');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                if(typeof json !== "object")
                {
                    me.data.ravens[ravenIndex].ravenstatus = [];
                }
                else if(!Array.isArray(json))
                {
                    me.data.ravens[ravenIndex].ravenstatus = [];
                }
                else
                {
                    me.data.ravens[ravenIndex].ravenstatus = json;
                }
            }

            me.dataChangeCallback(me.data);

        }).catch( (error) => {

            // Assume error means no data
            this.clearFetchFlag(ravenid, 'ravenstatus');

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                me.data.ravens[ravenIndex].ravenstatus = [];
            }

            me.dataChangeCallback(me.data);
        });

    }

    getUserInfo(raven, userid)
    {
        var curraven = raven.item;
        var ravenid = curraven['Id'];
        var uuid = curraven['Raven']['Raven UUID'];
        var version = curraven['Build']['OS Version'];

        if(!this.setAndCheckFetchFlag(ravenid, userid))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix1 + "ajax"
                + "?stage=" + this.data.stage
                + "&type=userinfo"
                + "&raven=" + userid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return [];
            }
        }).then((json) => {

            this.clearFetchFlag(ravenid, userid);

            // Requery as it is async and may have changed
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === ravenid }
            );

            if(ravenIndex >= 0)
            {
                if(!me.data.ravens[ravenIndex].users)
                    me.data.ravens[ravenIndex].users = []

                me.data.ravens[ravenIndex].users[userid] = json;
            }

            me.dataChangeCallback(me.data);
        });

    }

    ingestFleetRavenData(json) 
    {
        // Iterate through all Ravens updating the order counts
        let ordercounts = {};
        let provisionedcounts = {};
        var updatedorders;

        if(json.payload.ravens)
        {
            json.payload.ravens.forEach((elem) => {
                ordercounts[elem.orderNumber] = (ordercounts[elem.orderNumber] || 0) + 1;
                if(elem.provisionedDate)
                {
                    provisionedcounts[elem.orderNumber] = (provisionedcounts[elem.orderNumber] || 0) + 1;
                }
            });
        }

        if(json.payload.orders)
        {
            updatedorders = json.payload.orders.map((elem) => {
                elem.ravenCount = (ordercounts[elem.orderNumber] || 0);
                elem.provisionedCount = (provisionedcounts[elem.orderNumber] || 0);

                if (elem.ravenCount >= elem.quantity) {
                    // # of processed ravens on order(ravenCount) should
                    // never be > order quantity.
                    // Prevents glitch in test/qa when same raven is
                    // used for many test orders on account
                    elem.ravenCount = elem.quantity
                }

                return elem;
            });
        }

        return updatedorders;

    }

    getAccountInfo(accountid)
    {
        if(!this.setAndCheckFetchFlag('account', accountid))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return true;
        }

        var entryid = this.data.accounts.findIndex((elem) => { if(elem.account) return elem.account.accountId === accountid; return false;});
        if (entryid < 0)
        {
            return false;
        }

        var me = this;

        // perform ajax calls to get it the next state
        var url = "" + this.prefix2 + "queryfleetaccountdetails"
                + "?stage=" + this.data.stage
                + "&accountid=" + accountid;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                return {}
            }
        }).then((json) => {

            this.clearFetchFlag('account', accountid);

            var entryid = me.data.accounts.findIndex((elem) => { if(elem.account) return elem.account.accountId === accountid; return false;});

            if (entryid >= 0)
            {
                if(!json.payload)
                {
                    me.data.accounts[entryid].error = "Unable to query data";
                    me.dataChangeCallback(me.data);
                    return;
                }

                me.data.accounts[entryid] = json.payload;
                
                // Fleet needs to ingest the order data, temporary work around as only fleets return a single plan
                if ( me.data.accounts[entryid].plan ) {
                    let updatedorders = me.ingestFleetRavenData(json);
                    me.data.accounts[entryid].orders = updatedorders;
                }
            }

            me.dataChangeCallback(me.data);

        });


    }

    addFleetOrder(accountid, ordernumber, quantity, comment, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "addfleetorder"
                + "?stage=" + this.data.stage
                + "&accountid=" + accountid
                + "&ordernumber=" + ordernumber
                + "&quantity=" + quantity;

        if(comment) url += "&comment=" + comment;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {

            onSuccess(json);

        });


    }

    changeFleetOrder(accountid, ordernumber, quantity, comment, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "changefleetorder"
                + "?stage=" + this.data.stage
                + "&accountid=" + accountid
                + "&ordernumber=" + ordernumber;

        if(quantity || quantity == 0) url += "&quantity=" + quantity;
        if(comment) url += "&comment=" + comment;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {
            onSuccess(json);
        });


    }

    cancelFleetOrder(accountid, ordernumber, comment, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "cancelfleetorder?stage=" + this.data.stage
                + "&accountid=" + accountid
                + "&ordernumber=" + ordernumber
                + "&comment=" + comment;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {
            onSuccess(json);
        });
    }

    processShipments(ordernumber, ravenserialnumbers, shippeddate, onSuccess, onFail)
    {
        var me = this;

        // NOTE:
        // this function expects a moment processshipments lambda expects a str
        // in the format: "yyyy-MM-DDTHH:mm:ss" ex: "2022-03-28T12:00:00"
        if (shippeddate) {
          if (moment.isMoment(shippeddate)) {
            shippeddate = shippeddate.format('YYYY-MM-DDTHH:mm:ss');
          }
        }

        if (ravenserialnumbers) {
          if (Array.isArray(ravenserialnumbers)) {
            var serialset = new Set(ravenserialnumbers);
            if (serialset.size !== ravenserialnumbers.length) {
              onFail(`Each Raven Serial No. in the order must be unique. Received Raven Serial Numbers: ${ravenserialnumbers}`)
              return [];
            }
          }
        }

        var url = "" + this.prefix2 + "processshipments"
                + "?stage=" + this.data.stage
                + "&ordernumber=" + ordernumber
                + "&ravens=" + JSON.stringify(ravenserialnumbers)
                + "&shippeddate=" + shippeddate;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {
            if (json.status === "ERROR") {
                onFail(json.message);
                return[];
            }
            else {
              if (json.payload == 0) {
                onFail("Empty payload returned - double check serial numbers and order status.");
                return [];
              }

            onSuccess(json);
            }
        });


    }

    getRatePlans(stage) { // https://docs.klashwerks.com/ff-api-docs/#/default/get_rate_plans_rate_plans_get
        return fulfillmentApi(stage).get('/rate-plans')
        .then(
            (response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("Get rate plans unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                let ratePlansWithTerms = [];
                parsedResponse.forEach( (ratePlan) => {
                    if (ratePlan.terms) {
                        ratePlan.terms.forEach( (term) => {
                            ratePlansWithTerms.push({
                                id: ratePlan.id + "." + term.id, // RAV-2762 rush job
                                name: ratePlan.name + ", " + term.name
                            })
                        });
                    }
                });
                if (ratePlansWithTerms.length > 0) {
                    return ratePlansWithTerms;
                }
                return parsedResponse; // backwards compatibility
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    getItemTypes(stage) { // https://docs.klashwerks.com/ff-api-docs/#/default/get_item_types_item_types_get
        return fulfillmentApi(stage).get('/item-types')
        .then(
            (response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("Get item types unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                return parsedResponse;
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    addAccount(stage, email, externalId, name, notes, sendWelcomeEmail, integrations) {

        const requestBody = {
            email,
            externalId,
            name,
            notes,
            sendWelcomeEmail
        };
        if (integrations && integrations.length > 0) {
            requestBody.integrations = integrations;
        }
        return fulfillmentApi(stage)
        .post('/accounts', requestBody)
        .then(
            (response) => {

                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Add Account unexpected status " + response.status);
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    getAccount(stage, externalId) {

        return fulfillmentApi(stage).get(
            `/accounts/${externalId}`,
        ).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Get Account unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                return parsedResponse;
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    updateAccount(stage, externalId, updateDelta) {
        return fulfillmentApi(stage)
        .patch(`/accounts/${externalId}`, updateDelta)
        .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Update account unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse); // return order
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    getAccountGeotabIntegration(stage, externalId) {
        return fulfillmentApi(stage)
        .get(`/accounts/${externalId}/integrations/geotab`)
        .then(
            (response) => {
                if (response.ok) {
                    return response.json();
                }
                switch (response.status) {
                    case 404:
                        return Promise.resolve([]);
                    default:
                    const error = new Error("Update account unexpected status " + response.status);
                    //error.name = response.status;
                    error.code = response.status;
                    return Promise.reject(error);
                }
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse); // return parsed integration details
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        )
    }

    addAccountGeotabIntegration(stage, externalId, geotabDatabaseName, orderNumber = undefined) {

        const requestBody = {
            type: "GEOTAB",
            //name: "string",
            details: {
                databaseName: geotabDatabaseName,
                //databaseGuid: "string",
                //serviceAccount: {}
            }
        }
        if (orderNumber) {
            requestBody.addOrderNumber = orderNumber;
        }

        return fulfillmentApi(stage)
        .post(`/accounts/${externalId}/integrations`, requestBody)
        .then(
            (response) => {

                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Add Account Geotab integration unexpected status " + response.status);
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    deleteAccountGeotabIntegration(stage, externalId, integrationId = "geotab") {

        return fulfillmentApi(stage)
        .delete(`/accounts/${externalId}/integrations/${integrationId}`)
        .then(
            (response) => {
                if (response.ok) {
                    return Promise.resolve();
                }
                const error = new Error("Delete integration unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            () => {
                return Promise.resolve();
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    getAccountGeotabDigDevices(stage, orderNumber, generateCSV = false) {
        return fulfillmentApi(stage)
        .get(`/integrations/geotab-dig/devices${generateCSV ? "/csv" : ""}?order_number=${orderNumber}`)
        .then(
            (response) => {
                if (response.ok) {
                    if (generateCSV) {
                        return response.text();
                    }
                    return response.json();
                }
                switch (response.status) {
                    case 404:
                        return Promise.resolve([]);
                    default:
                    const error = new Error("Update account unexpected status " + response.status);
                    //error.name = response.status;
                    error.code = response.status;
                    return Promise.reject(error);
                }
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse); // return parsed integration details
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        )
    }

    /**
     * addShippingAddress calls /level2/addshippingaddress in the support backend
     * shippingAddress should be an object shaped like this:
     *
     * {
     *       accountId, // required
     *       name, // required
     *       address1, // required
     *       address2,
     *       city, // required
     *       state, // required
     *       country, // required
     *       postcode, // required
     *       phoneNumber,
     *       email,
     * }
     **/
    addShippingAddress(stage, shippingAddress) {

        if (!shippingAddress) {
            return Promise.reject(new Error('no shippingAddress provided'));
        }

        return fulfillmentApi(stage).post(
            `/accounts/${shippingAddress.accountId}/addresses`,
            shippingAddress,
        ).then((response) => {

                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("Add Shipping unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    /**
     * addOrder calls /level2/addorder in the support backend
     * order should be an object shaped like this:
     *
     * {
     *     accountId: int, // required - from account
     *     orderNumber: string, // required
     *     quantity: int, // required
     *     comment: string, max 255 char
     * }
     **/
    addOrder(stage, order) {

        if (!order) {
            return Promise.reject(new Error('no order provided'));
        }

        return fulfillmentApi(stage).post(
            `/accounts/${order.accountId}/orders`,
            order,
        ).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Add Order unexpected status " + response.status);
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    cancelOrder(stage, orderNumber) {
        return fulfillmentApi(stage).delete(
            `/orders/${orderNumber}`
        ).then((response) => {
                if (response.ok) {
                    return;
                }
                const error = new Error("Cancel order unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            () => {

                return Promise.resolve(); // return order item
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );

    }

    listOrders(stage, ordersFilterString) {

        let path = "/orders";
        if (ordersFilterString) {
            path += "?" + ordersFilterString;
        }

        return fulfillmentApi(stage).get(path)
        .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("List Orders unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                // response type is now `OrdersCollection`, which looks like:
                // {
                //   total: int, // total number of matched items (may be > results.length)
                //   results: [ Order ], // array of Order objects
                //   _prev: string, // url to use for prev page - Work in Progress!
                //   _next: string, // url to use for next page - Work in Progress!
                // }
                if (!this.data.orders || // initialize orders, if first-load
                    (this.data.stage !== stage)) { // switching stages, or first-load, clear orders from previous stage

                    this.data.stage = stage; // track current stage
                    this.data.orders = []; // initialize orders
                }

                parsedResponse.results.forEach( (order) => {
                    order.items.forEach( (item) => {
                        if (item.rateTermId) { // backwards compatibility
                            item.ratePlanId = item.ratePlanId + "." + item.rateTermId; // RAV-2762 rush job
                        }
                    });
                    // IMPORTANT: Add order to data store
                    this.data.orders.push(order);
                });

                return parsedResponse.results;
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }
    
    updateOrder(stage, orderNumber, updateDelta) {

        /* maintaining a local copy of all orders in ravenDataStore is deprecated (components to track orders in state for now)
        let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }*/

        return fulfillmentApi(stage).patch(
            `/orders/${orderNumber}`,
            updateDelta
        ).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Update order item unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                parsedResponse.items.forEach( (item) => {
                    if (item.rateTermId) { // backwards compatibility
                        item.ratePlanId = item.ratePlanId + "." + item.rateTermId; // RAV-2762 rush job
                    }
                });
                /*
                // update local store
                let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                this.data.orders[localOrderIndex] = parsedResponse; // update local data store
                */

                return Promise.resolve(parsedResponse); // return order
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    addOrderItem(stage, orderNumber, itemType = "RAVEN_PLUS", ratePlanId = "1", rateTermId = "1") { // RAV-2762 required adding rateTermId

        /* maintaining a local copy of all orders in ravenDataStore is deprecated (components to track orders in state for now)
        let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }*/

        const orderDelta = {
            itemType: itemType
        }
        if (ratePlanId) {
            orderDelta.ratePlanId = ratePlanId;
        }
        if (rateTermId) {
            orderDelta.rateTermId = rateTermId;
        }

        return fulfillmentApi(stage).post(`/orders/${orderNumber}/items`, orderDelta)
        .then(
            (response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Add order item unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                if (parsedResponse.hasOwnProperty("rateTermId")) { // backwards compatibility
                    parsedResponse.ratePlanId = parsedResponse.ratePlanId + "." + parsedResponse.rateTermId; // RAV-2762 rush job
                }

                /*
                // update local store
                let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                this.data.orders[localOrderIndex] = parsedResponse; // update local data store
                */

                return Promise.resolve(parsedResponse); // return order item
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    removeOrderItem(stage, orderNumber, orderItemId) {

        /* maintaining a local copy of all orders in ravenDataStore is deprecated (components to track orders in state for now)
        let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }*/

        return fulfillmentApi(stage).delete(
            `/orders/${orderNumber}/items/${orderItemId}`
        ).then((response) => {
                if (response.ok) {
                    return;
                }
                const error = new Error("Remove order item unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            () => {
                /*
                // update local store
                let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                this.data.orders[localOrderIndex] = parsedResponse; // update local data store
                */

                return Promise.resolve(); // return order item
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    updateOrderItem(stage, orderId, orderItemId, updateDelta) {

        /* maintaining a local copy of all orders in ravenDataStore is deprecated (components to track orders in state for now)
        let localOrderIndex = this.data.orders.findIndex((order) => order.orderId === orderId);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }

        let localOrderItemIndex = this.data.orders[localOrderIndex].items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            return Promise.reject(new Error("Data store's order does not list this order item."));
        }*/

        return fulfillmentApi(stage).patch(
            `/orderitems/${orderItemId}`,
            updateDelta
        ).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Update order item unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                if (parsedResponse.hasOwnProperty("rateTermId")) { // backwards compatibility
                    parsedResponse.ratePlanId = parsedResponse.ratePlanId + "." + parsedResponse.rateTermId; // RAV-2762 rush job
                }
                /*
                // update local store
                localOrderIndex = this.data.orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                localOrderItemIndex = this.data.orders[localOrderIndex].items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    return Promise.reject(new Error("Data store's order no longer lists this order item."));
                }

                this.data.orders[localOrderIndex].items[localOrderItemIndex] = parsedResponse; // update local data store
                */

                return Promise.resolve(parsedResponse); // return order
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    setOrderItem(stage, orderId, orderItemId, orderItem) {

        /* maintaining a local copy of all orders in ravenDataStore is deprecated (components to track orders in state for now)
        let localOrderIndex = this.data.orders.findIndex((order) => order.orderId === orderId);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }

        let localOrderItemIndex = this.data.orders[localOrderIndex].items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
        if (localOrderItemIndex === -1) {
            return Promise.reject(new Error("Data store's order does not list this order item."));
        }*/

        return fulfillmentApi(stage).put(
            `/orderitems/${orderItemId}`,
            orderItem
        ).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Set order item unexpected status " + response.status);
                //error.name = response.status;
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                if (parsedResponse.hasOwnProperty("rateTermId")) { // backwards compatibility
                    parsedResponse.ratePlanId = parsedResponse.ratePlanId + "." + parsedResponse.rateTermId; // RAV-2762 rush job
                }

                /*
                // update local store
                localOrderIndex = this.data.orders.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                localOrderItemIndex = this.data.orders[localOrderIndex].items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    return Promise.reject(new Error("Data store's order no longer lists this order item."));
                }

                this.data.orders[localOrderIndex].items[localOrderItemIndex] = parsedResponse; // update local data store
                */

                return Promise.resolve(parsedResponse); // return order
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    updateOrderStatusNext(stage, orderNumber) {

        let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }

        return fulfillmentApi(stage).post(`/orders/${orderNumber}/status/next`, {}).then(
            (response) => {

                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("Update order status unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                parsedResponse.items.forEach( (item) => {
                    if (item.rateTermId) { // backwards compatibility
                        item.ratePlanId = item.ratePlanId + "." + item.rateTermId; // RAV-2762 rush job
                    }
                });

                // update local store
                localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                this.data.orders[localOrderIndex] = parsedResponse;
                
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    updateOrderStatusBack(stage, orderNumber) {

        let localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
        if (localOrderIndex === -1) {
            return Promise.reject(new Error("Order not found in data store."));
        }

        return fulfillmentApi(stage).post(`/orders/${orderNumber}/status/back`, {}).then(
            (response) => {

                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(new Error("Update order status unexpected status " + response.status));
            },
            (error) => { return Promise.reject(error); }
        )
        .then(
            (parsedResponse) => {

                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }

                parsedResponse.items.forEach( (item) => {
                    if (item.rateTermId) { // backwards compatibility
                        item.ratePlanId = item.ratePlanId + "." + item.rateTermId; // RAV-2762 rush job
                    }
                });

                // update local store
                localOrderIndex = this.data.orders.findIndex((order) => order.orderNumber === orderNumber);
                if (localOrderIndex === -1) {
                    return Promise.reject(new Error("Order no longer available in data store."));
                }

                this.data.orders[localOrderIndex] = parsedResponse;
                
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
        )
        .catch(
            (error) => { return Promise.reject(error); }
        );
    }

    createFleetAccount(stage, channelid, email, name, recurlyToken, notes, plancode, planprice, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "createfleetaccount?stage=" + stage
                + "&channelid=" + channelid
                + "&email=" + encodeURIComponent(email)
                + "&name=" + encodeURIComponent(name);

        if(recurlyToken)
            url += "&billingtoken=" + recurlyToken.id;

        if(notes)
            url += "&notes=" + notes;

        if(plancode)
            url += "&plancode=" + plancode;

        if(parseInt(planprice) >= 0)
            url += "&planprice=" + planprice;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {

            if(json.status === "ERROR")
            {
                onFail(json.message);
            }
            else
            {
                onSuccess(json);
            }
        });


    }

    updateFleetAccount(stage, accountid, key, value, onSuccess, onFail)
    {
        var me = this;
        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "updatefleetaccount?stage=" + stage
                + "&accountid=" + accountid
                + "&" + key + "=" + encodeURIComponent(value)
                // + "&name=" + encodeURIComponent(name);

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {
            onSuccess(json);
        });


    }

    createFleetUser(stage, account_email, user_email, name, role, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "createfleetuser?stage=" + stage
                + "&accountemail=" + encodeURIComponent(account_email)
                + "&useremail=" + encodeURIComponent(user_email)
                + "&name=" + encodeURIComponent(name)
                + "&role=" + encodeURIComponent(role);

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {

            if(json.status === "ERROR")
            {
                onFail(json.message);
            }
            else
            {
                onSuccess(json);
            }
        });


    }

    updateFleetUser(stage, account_email, user_email, role, onSuccess, onFail)
    {
        var me = this;

        // perform ajax calls to get it the next state
        // FIXME - need to escape the comment
        var url = "" + this.prefix2 + "updatefleetuser?stage=" + stage
                + "&accountemail=" + encodeURIComponent(account_email)
                + "&useremail=" + encodeURIComponent(user_email)
                + "&role=" + encodeURIComponent(role);

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {

            if(response.ok)
            {
                return response.json();
            }
            else
            {
                onFail();
                return [];
            }
        }).then((json) => {

            if(json.status === "ERROR")
            {
                onFail(json.message);
            }
            else
            {
                onSuccess(json);
            }
        });


    }

    queryAccounts(stage)
    {
        // Refresh the login before attempting to grab account data
        CognitoUtil.refreshLogin();

        var me = this;

        if(!this.setAndCheckFetchFlag("accounts", "all"))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var url = "" + this.prefix1 + "ajax" +
            "?stage=" + stage +
            "&type=" + "accounts";

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state

              this.clearFetchFlag("accounts", "all");

              me.data.stage = stage;

              if(json && json.map)
              {
                  me.data.accounts = json.map((elem) => {
                        return {
                            account: elem
                        };
                  });
              }

              // this.setState({stage: stage, geojson: json[0], ravens: list });
              me.dataChangeCallback(me.data);

          }, (reason) => {
              // Ignore Failure

              // this.setState({stage: stage, geojson: geojsondata});
              me.dataChangeCallback(me.data);
          }
          );
    }

    queryUsers(stage)
    {
        // Refresh the login before attempting to grab account data
        CognitoUtil.refreshLogin();

        var me = this;

        if(!this.setAndCheckFetchFlag("users", "all"))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var url = "" + this.prefix1 + "ajax" +
            "?stage=" + stage +
            "&type=" + "users";

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state

              this.clearFetchFlag("users", "all");

              me.data.stage = stage;

              if(json && json.map)
              {
                  me.data.allusers = json.map((elem) => {
                        return {
                            user: elem
                        };
                  });
              }

              // this.setState({stage: stage, geojson: json[0], ravens: list });
              me.dataChangeCallback(me.data);

          }, (reason) => {
              // Ignore Failure

              // this.setState({stage: stage, geojson: geojsondata});
              me.dataChangeCallback(me.data);
          }
          );
    }

    queryFleetPlans(stage)
    {
        // Refresh the login before attempting to grab account data
           
        CognitoUtil.refreshLogin();

        var me = this;

        if(!this.setAndCheckFetchFlag("fleetplans", "all"))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var url = "" + this.prefix2 + "listserviceplans" +
            "?stage=" + stage;

        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } })
          .then((response) => {
            return response.json();
          }).then((json) => {
              // We grabbed the json.  Put it in the state

              this.clearFetchFlag("fleetplans", "all");

              if(Array.isArray(json))
              {
                  me.data.plans = json;
              }

              // this.setState({stage: stage, geojson: json[0], ravens: list });
              me.dataChangeCallback(me.data);

          }, (reason) => {
              // Ignore Failure

              // this.setState({stage: stage, geojson: geojsondata});
              me.dataChangeCallback(me.data);
          }
          );
    }

    mergeUpdate(list)
    {

        var me = this;

        if(me.data.ravens.length === 0)
        {
            me.data.ravens = list;
            return;
        }

        list.forEach((listelem) => {
            var ravenIndex = me.data.ravens.findIndex((elem) => 
                { return elem.item['Id'] === listelem.item['Id'] }
            );

            if(ravenIndex >= 0)
            {
                // merge the new data overtop of the old (keeping everything else)
                me.data.ravens[ravenIndex] = {...me.data.ravens[ravenIndex], ...listelem};
            }
        });
    }

    addToGeoJSON(geojson)
    {

        var me = this;

        if(!me.data.geojson || !me.data.geojson.data || !me.data.geojson.data.features)
        {
            return;
        }

        if(geojson.data.features.length !== 1 && geojson.objects.length !== 1)
        {
            console.log("Skipping adding to GeoJSON as there is more than one");
            return;
        }

        var feature = geojson.data.features[0];
        var object = geojson.objects[0];

        if(feature)
        {

            var ravenIndex = me.data.geojson.data.features.findIndex((elem) => 
                { return elem.properties['raven_id'] === feature.properties['raven_id'] }
            );

            if(ravenIndex >= 0)
            {
                var objectIndex = me.data.geojson.objects.findIndex((elem) => 
                    { return elem['Id'] === object['Id'] }
                );

                if(objectIndex >= 0)
                {
                    // merge the new data overtop of the old (keeping everything else)
                    me.data.geojson.objects[objectIndex] = {...me.data.geojson.objects[objectIndex], ...object};

                    feature.properties.index = objectIndex;

                    me.data.geojson.data.features[ravenIndex] = {...me.data.geojson.data.features[ravenIndex], ...feature};
                }

            };

        }

    }

    refreshRavenList(stage, val, prop, label)
    {
        // Refresh the login before attempting to grab data
           
        CognitoUtil.refreshLogin();

        var me = this;

        if(!this.setAndCheckFetchFlag(val, prop))
        {
            // Send the data change callback on the next event slice.  Avoids React error about recursion
            setTimeout( () => { this.dataChangeCallback(this.data) }, 10);
            return;
        }

        var url = "" + this.prefix1 + "ajaxbig" +
            "?stage=" + stage +
            "&type=" + val +
            "&prop=" + prop;

        return this.queryLoop(url, (json) => {
              // We grabbed the json.  Put it in the state
              this.clearFetchFlag(val, prop);

              var list = me.getCurFeatureList(json[0], prop);

              me.data.stage = stage;
              me.data.geojson = json[0];

              me.data.ravens = [];

              me.mergeUpdate(list);
              
              // this.setState({stage: stage, geojson: json[0], ravens: list });
              me.dataChangeCallback(me.data);

          }, (reason) => {
              // Wait Function

          }

          );
    }

    fakeListOrdersSource = [
        {
            orderId: 0,
            account: {
                email: "signal@eithyeight.com",
                externalId: 123
            },
            totalRavens: 4,
            items: [
                {
                    orderItemId: "00",//"UUID",
                    enclosureSerialNo: "1RVN11122233",
                    iccid: "8991112233334444556",
                    imei: "9911111122222233",
                    providerId: null,
                    errors: {
                        enclosureSerialNo: "Unknown error occurred",
                        imei: "Unknown error occurred"
                    }//,
                    //pending: undefined
                },
                {
                    orderItemId: "01",
                    enclosureSerialNo: "1RVN11122234",
                    iccid: "8991112233334444557",
                    imei: "9911111122222234",
                    providerId: null,
                    errors: {
                        iccid: "Unknown error occurred",
                        imei: "Unknown error occurred"
                    }
                },
                {
                    orderItemId: "02",
                    enclosureSerialNo: "1RVN11122235",
                    iccid: "8991112233334444558",
                    imei: "9911111122222235",
                    providerId: null,
                    errors: {
                        iccid: "Unknown error occurred"
                    }
                },
                {
                    orderItemId: "03",
                    enclosureSerialNo: "1RVN11122236",
                    iccid: "8991112233334444559",
                    imei: "9911111122222236",
                    providerId: null,
                    errors: {
                        iccid: "Unknown error occurred",
                        imei: "Unknown error occurred"
                    }
                }
            ]
        },
        {
            orderId: 1,
            account: {
                email: "company@aaa.com",
                externalId: 123
            },
            totalRavens: 2,
            items: [
                {
                    orderItemId: "10",
                    enclosureSerialNo: "1RVN11122233",
                    iccid: "8991112233334444556",
                    imei: "9911111122222233",
                    providerId: null
                },
                {
                    orderItemId: "11",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                }
            ]
        },
        {
            orderId: 2,
            account: {
                email: "company@aaa.com",
                externalId: 123
            },
            totalRavens: 5,
            items: [
                {
                    orderItemId: "20",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                },
                {
                    orderItemId: "21",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                },
                {
                    orderItemId: "22",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                },
                {
                    orderItemId: "23",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                },
                {
                    orderItemId: "24",
                    enclosureSerialNo: null,
                    iccid: null,
                    imei: null,
                    providerId: null
                }
            ]
        }
    ];

    fakeListOrders(stage) {

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                return resolve(JSON.parse(JSON.stringify(this.fakeListOrdersSource))); // return a deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)
                //return reject(new Error("…")); // ignored
            }, 3000);
        });
    };

    fakeUpdateOrderItem(orderId, orderItemId, enclosureSerialNo, providerId) {

        function randomDigit () {
            return Math.floor(Math.random() * 10);
        }
        function generateFakeIccid (orderItemId) {
            return "8991112" +
                orderItemId +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit();
        }

        function generateFakeImei (orderItemId) {
            return "9911111" +
                orderItemId +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit() +
                randomDigit();
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {

                const localOrderIndex = this.fakeListOrdersSource.findIndex((order) => order.orderId === orderId);
                if (localOrderIndex === -1) {
                    return reject(new Error("Fake order not found."));
                }

                const order = this.fakeListOrdersSource[localOrderIndex]; //[orderItemId.substring(0, 1)];

                const localOrderItemIndex = order.items.findIndex((orderItem) => orderItem.orderItemId === orderItemId);
                if (localOrderItemIndex === -1) {
                    return reject(new Error("Fake order item not found."));
                }

                const orderItem = order.items[localOrderItemIndex]; // [orderItemId.substring(1, 2)];

                if (enclosureSerialNo) {
                    orderItem.enclosureSerialNo = enclosureSerialNo;
                    orderItem.iccid = generateFakeIccid(orderItemId);
                    orderItem.imei = generateFakeImei(orderItemId);
                }

                if (providerId) {
                    orderItem.providerId = providerId;
                }

                order.items[localOrderItemIndex] = orderItem;
                this.fakeListOrdersSource[localOrderIndex] = order;
                
                return resolve(JSON.parse(JSON.stringify(orderItem))); // return a deep copy (summary of required spec https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704)
            }, 1500);
        });
    }

    changeRavenStatus(stage, ravenUuid, status) {
        const me = this;

        var url = "" + this.prefix2 + "modifystatus"
        + "?stage=" + stage
        + "&ravenunit=" + ravenUuid
        + "&status=" + status;


        return fetch(url,
            { method: 'POST', headers: { Authorization: CognitoUtil.getCurrentUserToken() } } )
          .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                const error = new Error("Change Raven status unexpected status " + response.status);
                error.code = response.status;
                return Promise.reject(error);
            },
            (error) => { return Promise.reject(error); }
            )
          .then((parsedResponse) => {
                if (parsedResponse.status === "ERROR") {
                    return Promise.reject(new Error(parsedResponse.message));
                }
                return Promise.resolve(parsedResponse);
            },
            (error) => { return Promise.reject(error); }
            )
          .catch((error) => {
            return Promise.reject(error);
          });
    }
}
